import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import './theme.scss'

function getStepLabels() {
    return ['Customer Information', 'ŠKODA Ident', 'Order Details', 'Consent Verification']
}

export default function HorizontalStepper({ activeStep }) {
    const stepLabels = getStepLabels()
    return (
        <div className="horizontal-stepper">
            <Stepper activeStep={activeStep} alternativeLabel style={{ background: 'none' }}>
                {stepLabels.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
}
