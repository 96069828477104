import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { SecondaryButton } from '../../Buttons/SecondaryButton'
import { useHistory } from 'react-router-dom'
import PrimaryButton from '../../Buttons/PrimaryButton'
import '../theme.scss'
import {
    NAVIGATION_BUTTONS_CONFIGURATION,
    SKODA_IDENT_STEP,
} from '../../../constants/RegistrationConstants'
import { SkipButton } from '../../Buttons/SkipButton'
import { useDispatch, useSelector } from 'react-redux'
import { CancelButton } from '../../Buttons/CancelButton'

function getButtonConfig(activeStep) {
    return NAVIGATION_BUTTONS_CONFIGURATION[activeStep]
}

export default function RegistrationNavigationButtons({ activeStep, onNextClick }) {
    const consentVerification = useSelector((state) => state.registration.consentVerification)
    const buttonConfig = getButtonConfig(activeStep)
    let history = useHistory()
    const dispatch = useDispatch()
    const [buttonDisabled, setButtonDisabled] = useState(true)

    const handleNext = () => {
        onNextClick()
    }

    const handleBack = () => {
        history.push({
            pathname: buttonConfig.backButtonUrl,
        })
    }

    const handleCancel = () => {}
    const handleSkip = () => {
        if (activeStep === SKODA_IDENT_STEP) {
            dispatch({
                type: 'REMOVE_SKODA_IDENT_DATA',
            })
        }
        history.push({
            pathname: buttonConfig.nextButtonUrl,
        })
    }

    useEffect(() => {
        setButtonDisabled(!consentVerification.gdprAgreed)
    }, [consentVerification.gdprAgreed])

    return (
        <React.Fragment>
            <div className="registration-navigation__wrapper">
                {buttonConfig.isBack && (
                    <div className="registration-navigation__back">
                        <SecondaryButton clickAction={handleBack} label="Back" isBack />
                    </div>
                )}
                {buttonConfig.isCancel && (
                    <SecondaryButton clickAction={handleCancel} label="Cancel" />
                )}
                <div className="registration-navigation__right">
                    <PrimaryButton
                        disabled={activeStep === 3 && buttonDisabled}
                        clickAction={handleNext}
                        label={buttonConfig.buttonLabel}
                    />
                    {buttonConfig.isSkip && (
                        <SkipButton clickAction={handleSkip} label="Skip this step" />
                    )}
                    {buttonConfig.isCancelCustomer && (
                        <CancelButton clickAction={handleCancel} label="Cancel New Customer" />
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

RegistrationNavigationButtons.propTypes = {
    activeStep: PropTypes.number.isRequired,
}
