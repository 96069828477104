export const spanishProvinces = [
    'ALAVA',
    'ALBACETE',
    'ALICANTE',
    'ALMERIA',
    'ASTURIAS',
    'AVILA',
    'BADAJOZ',
    'BALEARES',
    'BARCELONA',
    'BURGOS',
    'CACERES',
    'CADIZ',
    'CANTABRIA',
    'CASTELLON',
    'CIUDAD REAL',
    'CORDOBA',
    'CUENCA',
    'GERONA',
    'GRANADA',
    'GUADALAJARA',
    'GUIPUZCOA',
    'HUELVA',
    'HUESCA',
    'JAEN',
    'LAS PALMAS',
    'LA CORUNA',
    'LA RIOJA',
    'LEON',
    'LERIDA',
    'LUGO',
    'MADRID',
    'MALAGA',
    'MURCIA',
    'NAVARRA',
    'ORENSE',
    'PALENCIA',
    'PONTEVEDRA',
    'SALAMANCA',
    'SANTANDER',
    'SANTA CRUZ TENERIFE',
    'SEGOVIA',
    'SEVILLA',
    'SORIA',
    'TARRAGONA',
    'TERUEL',
    'TOLEDO',
    'VALENCIA',
    'VALLADOLID',
    'VIZCAYA',
    'ZAMORA',
    'ZARAGOZA',
]

export const canarianProvinces = [
    'CEUTA',
    'LAS PALMAS DE GRAN CANARIA',
    'MELILLA',
    'SANTA CRUZ DE TENERIFE',
]

export const irishCounties = [
    'ANTRIM',
    'ARMAGH',
    'CARLOW',
    'CAVAN',
    'CLARE',
    'CORK',
    'DERRY',
    'DONEGAL',
    'DOWN',
    'DUBLIN',
    'FERMANAGH',
    'GALWAY',
    'KERRY',
    'KILDARE',
    'KILKENNY',
    'LAOIS',
    'LEITRIM',
    'LIMERICK',
    'LONGFORD',
    'LOUTH',
    'MAYO',
    'MEATH',
    'MONAGHAN',
    'OFFALY',
    'ROSCOMMON',
    'SLIGO',
    'TIPPERARY',
    'TYRONE',
    'WATERFORD',
    'WESTMEATH',
    'WEXFORD',
    'WICKLOW',
]

export const polishProvinces = [
    'DOLNOSLASKIE',
    'KUJAWSKO POMORSKIE',
    'LODZKIE',
    'LUBELSKIE',
    'LUBUSKIE',
    'MALOPOLSKIE',
    'MAZOWIECKIE',
    'OPOLSKIE',
    'PODKARPACKIE',
    'PODLASKIE',
    'POMORSKIE',
    'SLASKIE',
    'SWIETOKRZYSKIE',
    'WARMINSKO MAZURSKIE',
    'WIELKOPOLSKIE',
    'ZACHODNIOPOMORSKIE',
]

export const countriesList = [
    { id: 1, name: 'Austria', countryCode: 'AT' },
    { id: 2, name: 'Belgium', countryCode: 'BE' },
    { id: 3, name: 'Bosnia and Herzegovina', countryCode: 'BA' },
    { id: 4, name: 'Bulgaria', countryCode: 'BG' },
    { id: 5, name: 'Canary Islands', countryCode: 'IC' },
    { id: 6, name: 'Croatia', countryCode: 'HR' },
    { id: 7, name: 'Cyprus', countryCode: 'CY' },
    { id: 8, name: 'Czech Republic', countryCode: 'CZ' },
    { id: 9, name: 'Denmark', countryCode: 'DK' },
    { id: 10, name: 'Estonia', countryCode: 'EE' },
    { id: 11, name: 'Finland', countryCode: 'FI' },
    { id: 12, name: 'France', countryCode: 'FR' },
    { id: 13, name: 'Germany', countryCode: 'DE' },
    { id: 14, name: 'Greece', countryCode: 'GR' },
    { id: 15, name: 'Hungary', countryCode: 'HU' },
    { id: 16, name: 'Iceland', countryCode: 'IS' },
    { id: 17, name: 'Ireland', countryCode: 'IE' },
    { id: 18, name: 'Italy', countryCode: 'IT' },
    { id: 19, name: 'Latvia', countryCode: 'LV' },
    { id: 20, name: 'Lithuania', countryCode: 'LT' },
    { id: 21, name: 'Luxembourg', countryCode: 'LU' },
    { id: 22, name: 'Malta', countryCode: 'MT' },
    { id: 23, name: 'Netherlands', countryCode: 'NL' },
    { id: 24, name: 'North Macedonia', countryCode: 'MK' },
    { id: 25, name: 'Norway', countryCode: 'NO' },
    { id: 26, name: 'Poland', countryCode: 'PL' },
    { id: 27, name: 'Portugal', countryCode: 'PT' },
    { id: 28, name: 'Romania', countryCode: 'RO' },
    { id: 29, name: 'Serbia', countryCode: 'RS' },
    { id: 30, name: 'Spain', countryCode: 'ES' },
    { id: 31, name: 'Sweden', countryCode: 'SE' },
    { id: 32, name: 'Switzerland', countryCode: 'CH' },
    { id: 33, name: 'Slovakia', countryCode: 'SK' },
    { id: 34, name: 'Slovenia', countryCode: 'SI' },
    { id: 35, name: 'Ukraine', countryCode: 'UA' },
    { id: 36, name: 'United Kingdom', countryCode: 'GB' },
]

export const addressFieldsConfiguration = {
    AT: {
        languageList: ['de', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                required: true,
                line: 1,
                label: 'Street',
            },
            number: {
                line: 1,
                label: 'Number',
            },
            floor: {
                line: 1,
                label: 'Floor',
            },
            room: {
                line: 1,
                label: 'Room',
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^(A-)?[0-9]{4}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    BA: {
        languageList: ['bs', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^[0-9]{5}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    BE: {
        languageList: ['fr', 'nl', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                required: true,
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^[0-9]{4}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    BG: {
        languageList: ['bg', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            buildingName: {
                line: 1,
                label: 'Building Name',
                width: 3,
            },
            room: {
                line: 1,
                label: 'Room',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            street: {
                line: 3,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 3,
                label: 'Number',
                width: 1,
            },
            district: {
                line: 4,
                label: 'District',
            },
            zipCode: {
                required: true,
                validation: '^(BG-)?[0-9]{4}$',
                line: 5,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 5,
                label: 'City',
                width: 3,
            },
        },
    },
    CH: {
        languageList: ['de', 'it', 'fr', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^(CH-)?[0-9]{4}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    CY: {
        languageList: ['el', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^(CY-)?[0-9]{4}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    CZ: {
        languageList: ['cs', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^[0-9]{3}\\s?[0-9]{2}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    DE: {
        languageList: ['de', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^[0-9]{5}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    DK: {
        languageList: ['da', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^(DK-)?[0-9]{4}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    EE: {
        languageList: ['et', 'ru', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^[0-9]{5}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    ES: {
        languageList: ['es', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^[0-9]{5}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
            province: {
                line: 4,
                label: 'Province',
                options: spanishProvinces,
            },
        },
    },
    IC: {
        languageList: ['es', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^[0-9]{5}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
            province: {
                line: 4,
                label: 'Province',
                options: canarianProvinces,
            },
        },
    },
    FI: {
        languageList: ['fi', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            zipCode: {
                required: true,
                validation: '^(FI-)?[0-9]{5}$',
                line: 2,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 2,
                label: 'City',
                width: 3,
            },
        },
    },
    FR: {
        languageList: ['fr', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^(F-|FR-)?[0-9]{5}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    GB: {
        languageList: ['en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            buildingName: {
                line: 1,
                label: 'Building Name',
            },
            number: {
                line: 2,
                label: 'Number',
                width: 1,
            },
            street: {
                line: 2,
                label: 'Street',
                width: 3,
            },
            additionalInformation: {
                line: 3,
                label: 'Additional Information',
            },
            city: {
                required: true,
                line: 4,
                label: 'Town',
                width: 3,
            },
            zipCode: {
                required: true,
                validation:
                    '^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$',
                line: 4,
                label: 'Zip Code',
                width: 1,
            },
        },
    },
    GR: {
        languageList: ['el', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^(GR-)?[0-9]{3}\\s?[0-9]{2}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    HR: {
        languageList: ['hr', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            zipCode: {
                required: true,
                validation: '^[0-9]{5}$',
                line: 2,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 2,
                label: 'City',
                width: 3,
            },
        },
    },
    HU: {
        languageList: ['hu', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^[0-9]{4}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    IE: {
        languageList: ['en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            addressLine1: {
                line: 1,
                label: 'Address Line 1',
            },
            addressLine2: {
                line: 2,
                label: 'Address Line 2',
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
            },
            county: {
                line: 4,
                label: 'County',
                width: 3,
                options: irishCounties,
            },
            eircode: {
                line: 4,
                label: 'Eircode',
                width: 1,
            },
        },
    },
    IS: {
        languageList: ['is', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^(IS-)?[0-9]{3}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    IT: {
        languageList: ['it', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^[0-9]{5}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 2,
            },
            provinceCode: {
                validation: '^[a-zA-Z]{2}$',
                line: 3,
                label: 'Province Code',
                width: 1,
            },
        },
    },
    LT: {
        languageList: ['lt', 'ru', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^(LT-)?[0-9]{4,5}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    LU: {
        languageList: ['de', 'fr', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^(LU-)?[0-9]{4}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    LV: {
        languageList: ['lv', 'ru', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^(LV-)?[0-9]{4}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    MK: {
        languageList: ['mk', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^(MK-)?[0-9]{5}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    MT: {
        languageList: ['en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                required: true,
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^[a-zA-Z]{3}\\s?[0-9]{4}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    NL: {
        languageList: ['nl', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^[0-9]{4}(\\s[a-zA-Z]{2})?$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    NO: {
        languageList: ['no', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^(N-|NO-)?[0-9]{4}',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    PL: {
        languageList: ['pl', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^[0-9]{2}-?[0-9]{3}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 2,
            },
            province: {
                line: 3,
                label: 'Province',
                width: 1,
                options: polishProvinces,
            },
        },
    },
    PT: {
        languageList: ['pt', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^[0-9]{4}-?[0-9]{3}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    RO: {
        languageList: ['ro', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                required: true,
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^[0-9]{6}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    RS: {
        languageList: ['sr', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^[0-9]{5}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    SE: {
        languageList: ['sv', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            zipCode: {
                required: true,
                validation: '^(SE-)?[0-9]{3}\\s?[0-9]{2}$',
                line: 2,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 2,
                label: 'City',
                width: 3,
            },
        },
    },
    SI: {
        languageList: ['sl', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^(SI-)?[0-9]{4}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    SK: {
        languageList: ['sk', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            additionalInformation: {
                line: 2,
                label: 'Additional Information',
            },
            zipCode: {
                required: true,
                validation: '^(SK-)?[0-9]{3}\\s?[0-9]{2}$',
                line: 3,
                label: 'Zip Code',
                width: 1,
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
                width: 3,
            },
        },
    },
    UA: {
        languageList: ['uk', 'en'],
        fields: {
            addressType: {
                required: true,
                label: 'Address Type',
            },
            street: {
                line: 1,
                label: 'Street',
                width: 3,
            },
            number: {
                line: 1,
                label: 'Number',
                width: 1,
            },
            floor: {
                line: 2,
                label: 'Floor',
            },
            room: {
                line: 2,
                label: 'Room',
            },
            city: {
                required: true,
                line: 3,
                label: 'City',
            },
            zipCode: {
                required: true,
                validation: '^[0-9]{5}$',
                line: 4,
                label: 'Zip Code',
            },
        },
    },
}

export const languagesList = {
    de: {
        en: 'German',
    },
    en: {
        en: 'English',
    },
    bs: {
        en: 'Bosnian',
    },
    fr: {
        en: 'French',
    },
    nl: {
        en: 'Dutch',
    },
    bg: {
        en: 'Bulgarian',
    },
    it: {
        en: 'Italian',
    },
    el: {
        en: 'Greek',
    },
    cs: {
        en: 'Czech',
    },
    da: {
        en: 'Danish',
    },
    et: {
        en: 'Estonian',
    },
    ru: {
        en: 'Russian',
    },
    es: {
        en: 'Spanish',
    },
    fi: {
        en: 'Finnish',
    },
    hr: {
        en: 'Croatian',
    },
    hu: {
        en: 'Hungarian',
    },
    is: {
        en: 'Icelandic',
    },
    lt: {
        en: 'Lithuanian',
    },
    lv: {
        en: 'Latvian',
    },
    mk: {
        en: 'Macedonian',
    },
    no: {
        en: 'Norwegian',
    },
    pl: {
        en: 'Polish',
    },
    pt: {
        en: 'Portuguese',
    },
    ro: {
        en: 'Romanian',
    },
    sr: {
        en: 'Serbian',
    },
    sl: {
        en: 'Slovenian',
    },
    sk: {
        en: 'Slovak',
    },
    uk: {
        en: 'Ukrainian',
    },
}

export const addressTypes = ['Home', 'Business', 'Billing']
