import React from 'react'
import tooltipIcon from '../../assets/images/tooltip.svg'
import Tooltip from '@material-ui/core/Tooltip'
import './theme.scss'

function TooltipBox({ text }) {
    return <div className="tooltip-box">{text}</div>
}

export default function InputTooltip({ text }) {
    return (
        <Tooltip
            title={<TooltipBox text={text} />}
            className="tooltip__block"
            placement="top"
            classes={{
                tooltip: 'tooltip-box__wrapper',
            }}
        >
            <img className="tooltip__icon" src={tooltipIcon} alt="" />
        </Tooltip>
    )
}
