import React, { useState, Fragment } from 'react'
import './index.scss'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Header from './components/Header/Header'
import SortingTable from './components/SortingTable/SortingTable'
import muiTheme from './muiTheme'
import { StylesProvider, MuiThemeProvider } from '@material-ui/core'
import CustomerInformationForm from './pages/Registration/CustomerInformationForm'
import SkodaIdentForm from './pages/Registration/SkodaIdentForm'
import OrderDetailsForm from './pages/Registration/OrderDetailsForm'
import ConsentVerificationForm from './pages/Registration/ConsentVerificationForm'
import ConsentDocumentsPreview from './pages/Documents/ConsentDocumentsPreview'
import Footer from './components/Footer/Footer'
import { DateTime } from 'luxon'

function App() {
    const [isAuthenticated, setAuthenticated] = useState(true)

    const mockData = [
        {
            comission: 124546,
            lastName: 'Homolka',
            firstName: 'Jaroslav',
            fpin: 548732,
            vin: null,
            created: '01.02.1987',
            planned_production_date: DateTime.utc(2020, 8, 12),
            enrolledDate: null,
            pairingCode: 1545457,
            handover: null,
            status: 'Complete',
        },
        {
            comission: 45334213,
            lastName: 'Stránský',
            firstName: 'Miroslav',
            fpin: null,
            vin: null,
            created: '05.07.2015',
            planned_production_date: DateTime.utc(2020, 9, 12),
            enrolledDate: DateTime.utc(2020, 7, 18).toLocaleString(),
            pairingCode: null,
            handover: '15.1.2018',
            status: 'Enter F-PIN',
        },
        {
            comission: 6543452,
            lastName: 'Hodný',
            firstName: 'Jiří',
            fpin: null,
            vin: null,
            created: '01.02.1987',
            planned_production_date: DateTime.utc(2020, 9, 12),
            enrolledDate: DateTime.utc(2020, 7, 19).toLocaleString(),
            pairingCode: 845696,
            handover: null,
            status: 'Enter VIN, F-PIN and Handover Date',
        },
        {
            comission: 12321354,
            lastName: 'Poctivý',
            firstName: 'Karel',
            fpin: 154879,
            vin: null,
            created: '05.07.2004',
            planned_production_date: DateTime.utc(2020, 8, 5),
            enrolledDate: DateTime.utc(2020, 7, 20).toLocaleString(),
            pairingCode: 45765910,
            handover: '11.5.2008',
            status: 'Complete',
        },
        {
            comission: 21423534,
            lastName: 'Neznamy',
            firstName: 'Jarda',
            fpin: null,
            vin: 1545875542,
            created: '05.07.2004',
            planned_production_date: DateTime.utc(2020, 8, 5),
            enrolledDate: DateTime.utc(2020, 8, 20).toLocaleString(),
            pairingCode: null,
            handover: '11.5.2008',
            status: 'Incomplete',
        },
    ]

    return (
        <Router>
            <StylesProvider injectFirst>
                <MuiThemeProvider theme={muiTheme}>
                    <Switch>
                        <Route path="/consent-documents">
                            <ConsentDocumentsPreview />
                        </Route>
                        <Fragment>
                            <Header />
                            <Route path="/login">
                                <Login fakeLoging={setAuthenticated} />
                            </Route>
                            <Route path="/customer-information">
                                <CustomerInformationForm />
                            </Route>
                            <Route path="/skoda-ident">
                                <SkodaIdentForm />
                            </Route>
                            <Route path="/order-details">
                                <OrderDetailsForm />
                            </Route>
                            <Route path="/consent-verification">
                                <ConsentVerificationForm />
                            </Route>
                            <ProtectedRoute path="/tasks" isAuth={isAuthenticated}>
                                <SortingTable
                                    title={'Daily Tasks'}
                                    tabs={[
                                        'All customers',
                                        'Vin & handover date',
                                        'Pairing code',
                                        'Handover date',
                                        'Process incomplete',
                                    ]}
                                    data={mockData}
                                />
                            </ProtectedRoute>
                            <ProtectedRoute path="/customers" isAuth={isAuthenticated}>
                                <SortingTable
                                    title={'Dealership Customers'}
                                    tabs={[
                                        'All customers',
                                        'Vin & handover date',
                                        'Pairing code',
                                        'Handover date',
                                        'Process incomplete',
                                    ]}
                                    data={mockData}
                                />
                            </ProtectedRoute>
                            <ProtectedRoute exact path="/" isAuth={isAuthenticated}>
                                <Dashboard />
                            </ProtectedRoute>
                            <Footer />
                        </Fragment>
                    </Switch>
                </MuiThemeProvider>
            </StylesProvider>
        </Router>
    )
}

function ProtectedRoute({ isAuth, children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuth ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    )
}

export default App
