import {
    CustomerInformationFormValidator,
    OrderDetailsFormValidator,
    SkodaIdentFormValidator,
} from '../pages/Registration/validators'

const skodaIdentDefault = {
    licenseNumber: '',
    issueDate: null,
    expirationDate: null,
    countryOfIssue: 0,
    dateOfBirth: null,
    errors: {
        licenseNumber: null,
        issueDate: null,
        expirationDate: null,
        countryOfIssue: null,
        dateOfBirth: null,
    },
    liveValidation: false,
}

const initialState = {
    loading: false,
    error: false,
    customerInformation: {
        email: 'prefilled@mail.cz',
        salutation: 1,
        firstName: 's',
        lastName: 's',
        middleName: 's',
        nickName: 's',
        country: 0,
        address: null,
        countryCode: 0,
        phoneNumber: '123456789',
        errors: {
            email: null,
            salutation: null,
            firstName: null,
            lastName: null,
            nickName: null,
            country: null,
            address: null,
            countryCode: null,
            phoneNumber: null,
        },
        liveValidation: false,
        addressValidationRules: {},
        noErrors: {
            email: null,
            salutation: null,
            firstName: null,
            lastName: null,
            nickName: null,
            country: null,
            address: null,
            countryCode: null,
            phoneNumber: null,
        },
    },
    skodaIdent: skodaIdentDefault,
    orderDetails: {
        commissionNumber: '',
        vin: '',
        preferredServicePartner: 'Example SKODA dealer, Vinohradska 420, Prague 3',
        handoverDate: null,
        plannedProductionDate: null,
        errors: {
            commissionNumber: null,
            vin: null,
            handoverDate: null,
            plannedProductionDate: null,
            oneOfDates: false,
        },
        liveValidation: false,
    },
    consentVerification: {
        gdprAgreed: false,
        activateServicesAgreed: true,
        pspSetupAgreed: true,
    },
}

export const registrationProcessReducer = (state = initialState, action) => {
    let validationErrors = {}
    switch (action.type) {
        case 'SAVE_CUSTOMER_INFORMATION':
            validationErrors = state.customerInformation.errors
            if (state.customerInformation.liveValidation) {
                const newCustomerInformation = {
                    ...state.customerInformation,
                    [action.payload.name]: action.payload.value,
                }
                validationErrors = CustomerInformationFormValidator(newCustomerInformation)
            }
            let newNickName = state.customerInformation.nickName
            if (action.payload.name === 'firstName') {
                newNickName =
                    action.payload.value + ' ' + state.customerInformation.lastName.substr(0, 1)
            }
            if (action.payload.name === 'lastName') {
                newNickName =
                    state.customerInformation.firstName + ' ' + action.payload.value.substr(0, 1)
            }
            if (action.payload.name === 'nickName') {
                newNickName = action.payload.value
            }
            return {
                ...state,
                customerInformation: {
                    ...state.customerInformation,
                    [action.payload.name]: action.payload.value,
                    nickName: newNickName,
                    errors: validationErrors,
                },
            }
        case 'SAVE_CUSTOMER_INFORMATION_ADDRESS_FIELD':
            validationErrors = state.customerInformation.errors
            if (state.customerInformation.liveValidation) {
                const newCustomerInformation = {
                    ...state.customerInformation,
                    address: {
                        ...state.customerInformation.address,
                        [action.payload.name]: action.payload.value,
                    },
                }
                validationErrors = CustomerInformationFormValidator(newCustomerInformation)
            }
            return {
                ...state,
                customerInformation: {
                    ...state.customerInformation,
                    address: {
                        ...state.customerInformation.address,
                        [action.payload.name]: action.payload.value,
                    },
                    errors: validationErrors,
                },
            }
        case 'SAVE_CUSTOMER_INFORMATION_ERRORS':
            return {
                ...state,
                customerInformation: {
                    ...state.customerInformation,
                    errors: action.payload.value,
                },
            }
        case 'SET_CUSTOMER_INFORMATION_LIVE_VALIDATION':
            return {
                ...state,
                customerInformation: {
                    ...state.customerInformation,
                    liveValidation: true,
                },
            }
        case 'ADD_ADDRESS_FIELDS':
            const newAddressFields = action.payload.value
            const errorFields = Object.entries(newAddressFields)
                .filter(([, v]) => v.required === true || v.validation)
                .map(([k]) => k)
            const validationRules = {}
            Object.entries(newAddressFields)
                .filter(([, v]) => v.required === true || v.validation)
                .forEach(([k, v]) => {
                    validationRules[k] = {
                        required: v.required,
                        validation: v.validation,
                    }
                })
            let newErrors = {
                ...state.customerInformation.errors,
                address: {},
            }
            let noErrors = {
                ...state.customerInformation.noErrors,
                address: {},
            }
            errorFields.forEach((f) => {
                newErrors.address[f] = null
                noErrors.address[f] = null
            })
            let newFields = {
                ...state.customerInformation,
                address: {},
            }
            Object.entries(newAddressFields).forEach(([k]) => {
                if (k === 'addressType') {
                    return (newFields.address[k] = 0)
                }
                return (newFields.address[k] = '')
            })
            newFields.address.language = 0
            newErrors.address.language = null
            noErrors.address.language = null
            newFields.errors = {
                ...newErrors,
            }
            newFields.noErrors = {
                ...noErrors,
            }
            newFields.addressValidationRules = validationRules
            return {
                ...state,
                customerInformation: newFields,
            }
        case 'SAVE_SKODA_IDENT':
            validationErrors = state.skodaIdent.errors
            if (state.skodaIdent.liveValidation) {
                const newSkodaIdent = {
                    ...state.skodaIdent,
                    [action.payload.name]: action.payload.value,
                }
                validationErrors = SkodaIdentFormValidator(newSkodaIdent)
            }
            return {
                ...state,
                skodaIdent: {
                    ...state.skodaIdent,
                    [action.payload.name]: action.payload.value,
                    errors: validationErrors,
                },
            }
        case 'REMOVE_SKODA_IDENT_DATA':
            return {
                ...state,
                skodaIdent: skodaIdentDefault,
            }
        case 'SAVE_SKODA_IDENT_ERRORS':
            return {
                ...state,
                skodaIdent: {
                    ...state.skodaIdent,
                    errors: action.payload.value,
                },
            }
        case 'SET_SKODA_IDENT_LIVE_VALIDATION':
            return {
                ...state,
                skodaIdent: {
                    ...state.skodaIdent,
                    liveValidation: true,
                },
            }
        case 'SAVE_ORDER_DETAILS':
            validationErrors = state.orderDetails.errors
            if (state.orderDetails.liveValidation) {
                const newOrderDetails = {
                    ...state.orderDetails,
                    [action.payload.name]: action.payload.value,
                }
                validationErrors = OrderDetailsFormValidator(newOrderDetails)
            }
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    [action.payload.name]: action.payload.value,
                    errors: validationErrors,
                },
            }
        case 'SAVE_ORDER_DETAILS_ERRORS':
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    errors: action.payload.value,
                },
            }
        case 'SET_ORDER_DETAILS_LIVE_VALIDATION':
            return {
                ...state,
                orderDetails: {
                    ...state.orderDetails,
                    liveValidation: true,
                },
            }
        case 'SAVE_CONSENT_VERIFICATION':
            return {
                ...state,
                consentVerification: {
                    ...state.consentVerification,
                    [action.payload.name]: action.payload.value,
                },
            }
        case 'SET_CUSTOMER_DATA_STARTED':
            return {
                ...state,
                loading: true,
            }
        case 'SET_CUSTOMER_DATA_SUCCESS':
            return {
                ...action.payload,
                loading: false,
                error: false,
            }
        case 'SET_CUSTOMER_DATA_FAILURE':
            return {
                ...state,
                loading: false,
                error: true,
            }
        default:
            return {
                ...state,
            }
    }
}
