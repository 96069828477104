// configureStore.js

import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
// import logger from 'redux-logger';
// import {USER_FOUND, USER_EXPIRED} from 'redux-oidc';
// import Logger from 'js-logger';
import createRootReducer from './reducers'
// import {setAccessToken, setIdToken} from './api';
// import ConfigurationHolder from './configurationHolder';
// import {i18nServiceInit} from './i18nService';

export const history = createBrowserHistory()

// // Dispatches when a valid user is found (on startup, after token refresh or token callback).
// const saveTokens = () => (next) => (action) => {
//   if (action.type === USER_FOUND) {
//     const accessToken = action.payload.access_token;
//     const idToken = action.payload.id_token;
//     if (accessToken && idToken) {
//       setAccessToken(accessToken);
//       setIdToken(idToken);
//     } else {
//       Logger.error('Access token and/or ID token not received.');
//     }
//   }
//   return next(action);
// };
//
// const initI18n = () => (next) => (action) => {
//   const {basePath} = ConfigurationHolder.getConfiguration();
//   if (action.type === USER_FOUND) {
//     i18nServiceInit(basePath, true);
//   }
//   if (action.type === USER_EXPIRED) {
//     i18nServiceInit(basePath, false);
//   }
//   return next(action);
// };

export default function configureStore(preloadedState) {
    const store = createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
        compose(
            composeWithDevTools(
                applyMiddleware(
                    thunk,
                    routerMiddleware(history) // for dispatching history actions
                    // saveTokens,
                    // initI18n,
                    // logger
                )
            )
        )
    )

    return store
}
