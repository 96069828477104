export const CUSTOMER_INFORMATION_STEP = 0
export const SKODA_IDENT_STEP = 1
export const ORDER_DETAILS_STEP = 2
export const CONSENT_VERIFICATION_STEP = 3

export const NAVIGATION_BUTTONS_CONFIGURATION = {
    '0': {
        isCancel: true,
        buttonLabel: 'Continue to Skoda Ident',
        nextButtonUrl: '/skoda-ident',
    },
    '1': {
        isBack: true,
        isSkip: true,
        buttonLabel: 'Continue to Order Details',
        backButtonUrl: '/customer-information',
        nextButtonUrl: '/order-details',
    },
    '2': {
        isBack: true,
        buttonLabel: 'Continue to Consents',
        backButtonUrl: '/skoda-ident',
        nextButtonUrl: '/consent-verification',
    },
    '3': {
        buttonLabel: 'Create New Customer',
        isCancelCustomer: true,
        nextButtonUrl: '/',
        backButtonUrl: '/order-details',
    },
}
