import React, { useEffect, useRef } from 'react'
import './theme.scss'

export const useOnOutsideClick = (handleOutsideClick) => {
    const innerBorderRef = useRef()

    const onClick = (event) => {
        if (innerBorderRef.current && !innerBorderRef.current.contains(event.target)) {
            handleOutsideClick()
        }
    }

    useMountEffect(() => {
        document.addEventListener('click', onClick, true)
        return () => {
            document.removeEventListener('click', onClick, true)
        }
    })

    return { innerBorderRef }
}

const useMountEffect = (fun) => useEffect(fun, [])

export default function PopUp({ title, body, onClose, open, actions }) {
    const { innerBorderRef } = useOnOutsideClick(onClose)
    return (
        <div className={open ? 'dialog dialog--open' : 'dialog'}>
            <div className="dialog__overlay"></div>
            <div ref={innerBorderRef} className="dialog__content">
                <div className={'dialog__header'}>
                    <div className={'dialog__header__title'}>{title}</div>
                    <div className={'dialog__header__closer'} onClick={onClose}>
                        <div className={'dialog__header__cross-icon'}></div>
                    </div>
                </div>
                <div className={'dialog__body'}>{body}</div>
                <div className={'dialog__actions'}>{actions}</div>
            </div>
        </div>
    )
}
