import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
    display: flex;
    height: 55px;
    padding: 0 30px;
    margin-top: 100px;
    background-color: #4a4a4a;
    align-items: center;
`
const LeftSide = styled.div`
    display: flex;
    flex: 1 0 auto;
`
const RightSide = styled.div`
    display: flex;
`
const Link = styled.div`
    margin-right: 40px;
    color: #c0c0c0;
    font-size: 12px;
    cursor: pointer;
`

export default function Footer() {
    return (
        <Root>
            <LeftSide>
                <Link>© ŠKODA AUTO a.s. 2020</Link>
                <Link>Imprint</Link>
                <Link>Legal</Link>
            </LeftSide>
            <RightSide>
                <Link>LANGUAGE</Link>
            </RightSide>
        </Root>
    )
}
