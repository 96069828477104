import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'

let muiTheme = createMuiTheme({
    palette: {
        primary: { main: '#228e22' },
        secondary: { main: '#4d4d4d' },
        fontFamily: '"SKODA Next", Arial, Helvetica, sans-serif',
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'SKODA Next, Arial, Helvetica, sans-serif',
        h4: {
            fontWeight: '700',
            fontSize: '1em',
            letterSpacing: '-1px',
        },
    },
    overrides: {
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottomColor: '#000',
                },
                '&:after': {
                    borderBottomColor: '#000',
                },
                '&$disabled': {
                    '&:before': {
                        borderBottomStyle: 'solid',
                    },
                },
            },
        },
        MuiFormLabel: {
            root: {
                '&$focused': {
                    color: '#808285',
                },
            },
        },
        MuiFormHelperText: {
            root: {
                textAlign: 'right',
            },
        },
        MuiSelect: {
            select: {
                paddingBottom: '10px',
                '&:focus': {
                    backgroundColor: 'none',
                },
            },
        },
        MuiStepIcon: {
            root: {
                color: '#fff',
                border: `1px solid #c0c0c0`,
                borderRadius: '50%',
                '&$completed': {
                    color: '#4b4a4b',
                    border: `none`,
                },
                '&$active': {
                    color: '#4ba82e',
                    border: `none`,
                    '&& text': {
                        fill: '#fff',
                    },
                },
            },
            text: {
                fill: '#4b4a4b',
            },
            active: {},
            completed: {},
        },
        MuiStepLabel: {
            label: {
                '&$completed': {
                    color: '#4b4a4b',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: '1.71',
                    letterSpacing: 'normal',
                },
                '&$active': {
                    color: '#4ba82e',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: '1.71',
                    letterSpacing: 'normal',
                },
            },
        },
        MuiStepConnector: {
            alternativeLabel: {
                left: `calc(-50% + 12px)`,
                right: `calc(50% + 12px)`,
            },
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: '#4ba82e',
            },
        },
        MuiInputAdornment: {
            root: {
                '&& button': {
                    padding: '0',
                },
            },
        },
    },
})
muiTheme = responsiveFontSizes(muiTheme)

export default muiTheme
