import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './assets/fonts/SKODANext-Black.ttf'
import configureStore, { history } from './configureStore'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'

// Init redux store
const initialState = {}
export const store = configureStore(initialState)

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
