import React, { useState } from 'react'
import './theme.scss'

export default function Checkbox({ isDisabled, onClick, label, isChecked }) {
    const [checked, setChecked] = useState(isChecked)

    const toggleCheck = () => {
        setChecked(!checked)
        onClick()
    }

    return (
        <span className="checkbox" onClick={isDisabled ? () => {} : toggleCheck}>
            <input
                className="checkbox__input"
                aria-label="checkbox"
                type="checkbox"
                checked={checked}
                onChange={toggleCheck}
            />
            <span className="checkbox__custom" />
            <span className="checkbox__label">{label}</span>
        </span>
    )
}
