import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { registrationProcessReducer } from './reducers/registrationProcessReducer'
import { dealerReducer } from './reducers/dealerReducer'

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        registration: registrationProcessReducer,
        dealer: dealerReducer,
    })
