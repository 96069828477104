import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import './theme.scss'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import checkmark from '../../assets/images/checkmark.svg'
import beak from '../../assets/images/beak-down.svg'
import Icon from '@material-ui/core/Icon'
import { getErrorText } from '../../pages/Registration/validators'

export function SelectIcon() {
    return (
        <Icon className="text-input-select-icon">
            <img src={beak} alt="beak" />
        </Icon>
    )
}

export default function TextInput({
    formId,
    inputId,
    handleChange,
    label,
    value,
    options,
    error,
    disabled,
    fullWidth,
}) {
    const textInputId = `${formId}-${inputId}-input`
    const textLabelId = `${textInputId}-label`

    const isError = error !== null && error !== undefined
    const isDisabled = disabled !== undefined

    const ITEM_HEIGHT = 48
    const ITEM_PADDING_TOP = 8
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            },
        },
    }
    const renderInput = (
        <>
            <TextField
                value={value}
                onChange={handleChange}
                id={textInputId}
                name={inputId}
                label={label}
                aria-describedby="component-error-text"
                error={isError}
                disabled={isDisabled}
            />
            {!isError && value !== '' && !isDisabled && (
                <img className="text-input-checkmark" src={checkmark} width={16} alt="checkmark" />
            )}
        </>
    )

    const renderSelect = (
        <React.Fragment>
            <InputLabel htmlFor={textInputId} className="text-input__label" error={isError}>
                {label}
            </InputLabel>
            <Select
                labelId={textLabelId}
                id={textInputId}
                name={inputId}
                value={value}
                onChange={handleChange}
                aria-describedby="component-error-text"
                error={isError}
                IconComponent={() => <SelectIcon />}
                MenuProps={MenuProps}
            >
                {options &&
                    options.map((option, index) => {
                        return (
                            <MenuItem value={index} key={index}>
                                {option}
                            </MenuItem>
                        )
                    })}
            </Select>
        </React.Fragment>
    )

    return (
        <div className="text-input-wrapper">
            <FormControl className={!fullWidth ? 'text-input' : null} error={isError}>
                {options ? renderSelect : renderInput}
                {isError && (
                    <FormHelperText
                        id="component-error-text"
                        error={isError}
                        className="text-input-helper"
                    >
                        {label + getErrorText(error)}
                    </FormHelperText>
                )}
            </FormControl>
        </div>
    )
}
