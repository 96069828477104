import React from 'react'
import './theme.scss'
import skip from '../../assets/images/icon-arrow-right-light.svg'

export function SkipButton({ clickAction, label }) {
    return (
        <div className="button button--skip" onClick={clickAction}>
            {label}
            <img className="button-skip-icon" src={skip} width={16} alt="skip" />
        </div>
    )
}
