import React, { useCallback, useState, Fragment } from 'react'
import styled from 'styled-components'
import DatePicker from '../Form/DatePicker'
import { DateTime, Duration, Interval } from 'luxon'
import { useSelector } from 'react-redux'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { SelectIcon } from '../Form/TextInput'
import { SecondaryButton } from '../Buttons/SecondaryButton'
import { useTable, useSortBy } from 'react-table'

const TableWrapper = styled.div`
    padding: 50px 50px 0 50px;
    min-height: 30vw;
`
const Heading = styled.div`
    color: #4b4a4b;
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 32px;
`
const FilterRow = styled.div`
    display: flex;
    position: relative;
    margin-bottom: 32px;
`
const FilterNav = styled.div`
    display: flex;
    flex: 1 1 auto;
`
const Filter = styled.div`
    color: #4b4a4b;
    cursor: pointer;
    font-size: 14px;
    align-self: flex-end;
`
const FilterPopUp = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 34px;
    right: 0;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    padding: 32px;
`
const Filters = styled.div`
    display: flex;
    margin-bottom: 42px;
`
const FilterColumn = styled.div`
    display: flex;
    flex-flow: column wrap;
    align-self: flex-start;
    flex: 1 1 auto;
`
const StatusFilterColumn = styled(FilterColumn)`
    display: flex;
    flex: 1 0 250px;
`
const LabelColumn = styled.div`
    align-self: flex-start;
    font-weight: bold;
`
const ContentColumn = styled.div`
    display: flex;
    font-weight: bold;
    margin-right: 30px;
`
const StatusContentColumn = styled(ContentColumn)`
    flex: 1 0 auto;
`
const NavFilterItem = styled.div`
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: bold;
    color: #808285;
    text-transform: uppercase;
    margin-right: 40px;
    cursor: pointer;
    position: relative;
    &:after {
        ${({ active }) =>
            active &&
            `
            content: '';
            background-color: #4ba82e;
            position: absolute;
            bottom: -8px;
            left:0;
            width: 100%;
            height: 3px;
        `}
    }
`
const DateSeparator = styled.div`
    background-color: #808285;
    align-self: center;
    content: '';
    margin: 0 20px;
    width: 8px;
    height: 1px;
`
const StatusSelect = styled(Select)`
    margin-top: 12px;
`
const FilterDatePicker = styled(DatePicker)`
    flex: 0 1 50px;
`
const ArrowDown = styled.div`
    transform: rotate(-90deg);
    display: inline-block;
    margin-left: 10px;
`
const ArrowUp = styled.div`
    transform: rotate(90deg);
    display: inline-block;
    margin-left: 10px;
`

const Styles = styled.div`
    table {
        font-family: 'SKODA Next', sans-serif;
        font-size: 14px;
        width: 100%;
        border-spacing: 0px 8px;

        thead {
            text-transform: uppercase;
            font-size: 10px;
            color: #808285;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
        }

        tbody {
            tr {
                background: white;
            }
        }

        th,
        td {
            padding: 1.5em;
            text-align: left;
        }
    }
`

function Table({ columns, data }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
        },
        useSortBy
    )

    const firstPageRows = rows.slice(0, 20)

    return (
        <Fragment>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    {column.isSorted ? (
                                        column.isSortedDesc ? (
                                            <ArrowDown>></ArrowDown>
                                        ) : (
                                            <ArrowUp>></ArrowUp>
                                        )
                                    ) : (
                                        ''
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {firstPageRows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <br />
            <div>Showing the first 20 results of {rows.length} rows</div>
        </Fragment>
    )
}

export default function SortingTable({ title, tabs, data }) {
    const skodaIdent = useSelector((state) => state.registration.skodaIdent)
    const errors = useSelector((state) => state.registration.skodaIdent.errors)
    const [openFilter, setOpenFilter] = useState(false)
    const [filterStatus, setFilterStatus] = useState('')
    const [tableData, setTableData] = useState(data)
    const [activeTab, setActiveTab] = useState(0)
    const mockSelect = ['Enter F-PIN', 'Enter Handover Date', 'DMS incomplete']

    const onShowFilter = useCallback(() => {
        setOpenFilter(!openFilter)
    }, [openFilter])

    const formId = 'filter-table'

    const handleChange = (e) => {
        setFilterStatus(e.target.value)
    }
    const columns = React.useMemo(
        () => [
            {
                Header: 'Comission',
                accessor: 'comission',
            },
            {
                Header: 'First Name',
                accessor: 'firstName',
            },
            {
                Header: 'Last Name',
                accessor: 'lastName',
            },
            {
                Header: 'F-PIN',
                accessor: 'fpin',
            },
            {
                Header: 'VIN',
                accessor: 'vin',
            },
            {
                Header: 'Created',
                accessor: 'created',
            },
            {
                Header: 'Pairing Code',
                accessor: 'pairingCode',
            },
            {
                Header: 'Enrolled',
                accessor: 'enrolledDate',
            },
            {
                Header: 'Handover',
                accessor: 'handover',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
        ],
        []
    )

    const sortByTab = useCallback(
        (tab, index) => (e) => {
            setActiveTab(index)
            let monthInFuture = Interval.after(DateTime.utc(), Duration.fromISO('P1M'))
            let twoDaysInFuture = Interval.after(DateTime.utc(), Duration.fromISO('P2D'))

            const filteredData = tableData.filter((item) => {
                switch (tab) {
                    case 'All customers':
                        return item
                    case 'Vin & handover date':
                        return (
                            item.planned_production_date < monthInFuture.end &&
                            (item.vin === null || item.handover === null)
                        )
                    case 'Pairing code':
                        return (
                            item.handover < twoDaysInFuture.end &&
                            item.enrolledDate === null &&
                            item.pairingCode
                        )
                    case 'Handover date':
                        return item.handover < twoDaysInFuture.end
                    case 'Process incomplete':
                        return item.handover < twoDaysInFuture.end
                }
            })
            setTableData(filteredData)
        },
        [tableData]
    )

    return (
        <TableWrapper>
            <Heading>{title}</Heading>
            <FilterRow>
                <FilterNav>
                    {tabs.map((tab, index) => (
                        <NavFilterItem
                            key={`${index}-tab`}
                            onClick={sortByTab(tab, index)}
                            active={index === activeTab ? true : false}
                        >
                            {tab}
                        </NavFilterItem>
                    ))}
                </FilterNav>
                <Filter onClick={onShowFilter}>Filter</Filter>
                {openFilter && (
                    <FilterPopUp>
                        <Filters>
                            <FilterColumn>
                                <LabelColumn>Created on</LabelColumn>
                                <ContentColumn>
                                    <FilterDatePicker />
                                    <DateSeparator />
                                    <FilterDatePicker />
                                </ContentColumn>
                            </FilterColumn>
                            <StatusFilterColumn>
                                <LabelColumn>Status</LabelColumn>
                                <StatusContentColumn>
                                    <StatusSelect
                                        // formId={formId}
                                        onChange={handleChange}
                                        name={'filterStatus'}
                                        value={filterStatus}
                                        IconComponent={() => <SelectIcon />}
                                        fullWidth
                                    >
                                        {mockSelect &&
                                            mockSelect.map((option, index) => {
                                                return (
                                                    <MenuItem value={index} key={index}>
                                                        {option}
                                                    </MenuItem>
                                                )
                                            })}
                                    </StatusSelect>
                                </StatusContentColumn>
                            </StatusFilterColumn>
                        </Filters>
                        <SecondaryButton label="Apply" />
                    </FilterPopUp>
                )}
            </FilterRow>
            <Styles>
                <Table columns={columns} data={tableData} />
            </Styles>
        </TableWrapper>
    )
}
