const initialState = {
    loading: false,
    error: false,
    dealer: {
        id: 'DEALER_NAME',
    },
}

export const dealerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DEALER_DATA_STARTED':
            return {
                ...state,
                loading: true,
            }
        case 'SET_DEALER_DATA_SUCCESS':
            return {
                ...action.payload,
                loading: false,
                error: false,
            }
        case 'SET_DEALER_DATA_FAILURE':
            return {
                ...state,
                loading: false,
                error: true,
            }
        default:
            return {
                ...state,
            }
    }
}
