export const EMPTY = 'empty'
export const INVALID = 'invalid'
export const EXIST = 'exist'
export const INVALID_DATE = 'invalid_date'
export const INVALID_PHONE = 'invalid_phone'

export const PAST = 'past'
export const FUTURE = 'future'

export function getErrorText(errorCode) {
    switch (errorCode) {
        case EMPTY:
            return ' is mandatory.'
        case INVALID:
            return ' is in an invalid format.'
        case PAST:
            return ' should be in the past.'
        case FUTURE:
            return ' should be in the future.'
        case EXIST:
            return ' already exist.'
        case INVALID_DATE:
            return '-'
        case INVALID_PHONE:
            return ' is in an invalid format. Use international format without country code, e.g. 777111222.'
        default:
            return ''
    }
}

const validateAddress = (address, validationRules) => {
    let errors = {}

    Object.entries(validationRules).forEach(([k, v]) => {
        if (v.required) {
            if (address[k] === '') {
                errors[k] = EMPTY
            } else {
                errors[k] = null
            }
        }
        if (v.validation) {
            const re = new RegExp(v.validation)
            errors[k] = re.test(address[k]) ? null : INVALID
        }
    })
    errors.language = address && address.language !== null ? null : INVALID
    return errors
}

export const validateEmail = (value) => {
    if (value === '') {
        return EMPTY
    }
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(value).toLowerCase()) ? null : INVALID
}

const validateVin = (value) => {
    if (value === '') {
        return null
    }
    const re = new RegExp('^[A-Z0-9]{17}$')
    return re.test(String(value).toUpperCase()) ? null : INVALID
}

const validateSelect = (value) => {
    if (value === 0) {
        return EMPTY
    }
    return value > 0 ? null : INVALID
}

const validateString = (value, length) => {
    if (value === '') {
        return EMPTY
    }
    return value.length > 0 ? null : INVALID
}

const validateNumbers = (value, minLength, maxLength) => {
    if (value === '') {
        return EMPTY
    }
    const re =
        minLength !== undefined
            ? new RegExp('^[0-9]{' + minLength + ',' + maxLength + '}$')
            : /^[0-9]*$/
    return re.test(String(value).toLowerCase()) ? null : INVALID
}

const validatePhone = (value, minLength, maxLength) => {
    if (value === '') {
        return EMPTY
    }
    const re = new RegExp('^[0-9]{' + minLength + ',' + maxLength + '}$')
    return re.test(String(value).toLowerCase()) ? null : INVALID_PHONE
}

const validateDate = (value, period) => {
    if (value === null) {
        return EMPTY
    }
    if (Object.prototype.toString.call(value) === '[object Date]') {
        if (isNaN(value.getTime())) {
            return INVALID_DATE
        } else {
            if (value.getFullYear() >= 2100 || value.getFullYear() <= 1900) {
                return INVALID_DATE
            }
            const now = new Date().setHours(0, 0, 0, 0)
            if (PAST === period) {
                return Date.parse(value) - now < 0 ? null : PAST
            } else if (FUTURE === period) {
                return Date.parse(value) - now >= 0 ? null : FUTURE
            }
            return null
        }
    } else {
        return INVALID_DATE
    }
}

export function CustomerInformationFormValidator(customerInformation) {
    const validationErrors = customerInformation.errors
    return {
        ...validationErrors,
        email: validateEmail(customerInformation.email),
        salutation: validateSelect(customerInformation.salutation),
        firstName: validateString(customerInformation.firstName),
        lastName: validateString(customerInformation.lastName),
        nickName: validateString(customerInformation.nickName),
        country: validateSelect(customerInformation.country),
        countryCode: validateSelect(customerInformation.countryCode),
        phoneNumber: validatePhone(customerInformation.phoneNumber, 4, 15),
        address: validateAddress(
            customerInformation.address,
            customerInformation.addressValidationRules
        ),
    }
}

export function SkodaIdentFormValidator(skodaIdent) {
    const validationErrors = skodaIdent.errors

    return {
        ...validationErrors,
        licenseNumber: validateString(skodaIdent.licenseNumber),
        issueDate: validateDate(skodaIdent.issueDate, PAST),
        expirationDate: validateDate(skodaIdent.expirationDate, FUTURE),
        countryOfIssue: validateSelect(skodaIdent.countryOfIssue),
        dateOfBirth: validateDate(skodaIdent.dateOfBirth, PAST),
    }
}

export function OrderDetailsFormValidator(orderDetails) {
    const validationErrors = orderDetails.errors
    const handoverDate = validateDate(orderDetails.handoverDate, FUTURE)
    const plannedProductionDate = validateDate(orderDetails.plannedProductionDate, FUTURE)

    return {
        ...validationErrors,
        commissionNumber: validateNumbers(orderDetails.commissionNumber, undefined, undefined),
        vin: validateVin(orderDetails.vin),
        handoverDate: handoverDate,
        plannedProductionDate: plannedProductionDate,
        oneOfDates: handoverDate === null || plannedProductionDate === null,
    }
}
