import React from 'react'
import './theme.scss'

export function PrintButton({ clickAction, label }) {
    return (
        <div className="button button--secondary button--print" onClick={clickAction}>
            {label}
        </div>
    )
}
