const mainMenuItems = [
    {
        label: 'My Daily Tasks',
        to: '/tasks',
    },
    {
        label: 'My Customers',
        to: '/customers',
    },
    {
        label: 'Create new Customer',
        to: '/create',
        type: 'createNewCustomer',
    },
]

export default mainMenuItems
