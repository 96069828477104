import React, { useEffect } from 'react'
import { MuiThemeProvider } from 'material-ui'
import TextInput from '../../components/Form/TextInput'
import HorizontalStepper from '../../components/Registration/Stepper/HorizontalStepper'
import RegistrationNavigationButtons from '../../components/Registration/Navigation/RegistrationNavigationButtons'
import {
    CUSTOMER_INFORMATION_STEP,
    NAVIGATION_BUTTONS_CONFIGURATION,
} from '../../constants/RegistrationConstants'
import { useDispatch, useSelector } from 'react-redux'
import FormWrapper from '../../components/Registration/Panel/FormWrapper'
import Panel from '../../components/Registration/Panel/Panel'
import './theme.scss'
import { useHistory } from 'react-router-dom'
import { CustomerInformationFormValidator } from './validators'
import {
    addressFieldsConfiguration,
    addressTypes,
    countriesList,
    languagesList,
} from '../../configuration/CountriesAddressBuilderConfiguration'
import { countryPhoneCodes } from '../../configuration/CountryPhoneCodesConfiguration'

export default function CustomerInformationForm() {
    const customerInformation = useSelector((state) => state.registration.customerInformation)
    const { errors, noErrors } = customerInformation

    const dispatch = useDispatch()
    let history = useHistory()

    const formId = 'customer-information'
    const salutationOptions = ['Choose', 'Mr.', 'Mrs.']
    let countryOptions = countriesList.map((c) => c.name)
    countryOptions.unshift('Choose')
    let countryCodeOptions = countryPhoneCodes.map((c) => '(' + c.dial_code + ') ' + c.name)
    countryCodeOptions.unshift('Choose')
    const buttonConfig = NAVIGATION_BUTTONS_CONFIGURATION[CUSTOMER_INFORMATION_STEP]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const validate = () => {
        const validationErrors = CustomerInformationFormValidator(customerInformation)
        dispatch({
            type: 'SAVE_CUSTOMER_INFORMATION_ERRORS',
            payload: {
                value: validationErrors,
            },
        })
        return validationErrors
    }

    const handleChange = (event) => {
        event.persist()
        dispatch({
            type: 'SAVE_CUSTOMER_INFORMATION',
            payload: {
                name: event.target.name,
                value: event.target.value,
            },
        })
    }

    const handleChangeAddressField = (event) => {
        event.persist()
        dispatch({
            type: 'SAVE_CUSTOMER_INFORMATION_ADDRESS_FIELD',
            payload: {
                name: event.target.name,
                value: event.target.value,
            },
        })
    }

    const handleChangeCountry = (event) => {
        event.persist()
        dispatch({
            type: 'SAVE_CUSTOMER_INFORMATION',
            payload: {
                name: event.target.name,
                value: event.target.value,
            },
        })
        const countryId = countriesList.find((c) => c.id === event.target.value).countryCode
        const config = addressFieldsConfiguration[countryId]

        dispatch({
            type: 'ADD_ADDRESS_FIELDS',
            payload: {
                value: config.fields,
            },
        })
    }

    const isFormValid = () => {
        const validationErrors = validate()
        return JSON.stringify(noErrors) === JSON.stringify(validationErrors)
    }

    const onNextClick = () => {
        if (isFormValid()) {
            history.push({
                pathname: buttonConfig.nextButtonUrl,
            })
        }
        dispatch({
            type: 'SET_CUSTOMER_INFORMATION_LIVE_VALIDATION',
        })
    }

    const countLines = (fields) => {
        let linesCount = 0
        Object.entries(fields).forEach(([k, v]) => {
            if (v.line && v.line > linesCount) {
                linesCount = v.line
            }
        })
        let lines = []
        for (let i = 0; i < linesCount; i++) {
            lines.push({})
        }
        Object.entries(fields).forEach(([k, v]) => {
            if (v.line) {
                lines[v.line - 1][k] = v
            }
        })
        return lines
    }

    const renderFieldsOnLine = (line, i, count) => {
        let className
        switch (count) {
            case 2:
                className = 'registration-step-field--half'
                break
            case 3:
                className = 'registration-step-field--third'
                break
            case 4:
                className = 'registration-step-field--quarter'
                break
            default:
                className = 'registration-step-field'
        }
        return (
            <div className="registration-form-row" key={i}>
                {Object.entries(line).map(([k, v], j) => {
                    if (v.width) {
                        switch (v.width) {
                            case 1:
                                className = 'registration-step-field--quarter'
                                break
                            case 2:
                                className = 'registration-step-field--half'
                                break
                            case 3:
                                className = 'registration-step-field--three'
                                break
                            default:
                                className = 'registration-step-field'
                        }
                    }
                    return (
                        <div className={className} key={j}>
                            <TextInput
                                formId={formId}
                                handleChange={handleChangeAddressField}
                                inputId={k}
                                label={v.label}
                                value={customerInformation.address[k]}
                                error={errors.address[k]}
                                options={v.options}
                            />
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderAddressLine = (line, i) => {
        const fieldCount = Object.keys(line).length
        if (fieldCount === 0) {
            return null
        }
        return renderFieldsOnLine(line, i, fieldCount)
    }

    const renderAddress = () => {
        const countryId = countriesList.find((c) => c.id === customerInformation.country)
            .countryCode
        const config = addressFieldsConfiguration[countryId]
        const languageCodes = config.languageList
        const languages = languageCodes.map((c) => languagesList[c].en)
        if (customerInformation.address === null) {
            return null
        }
        const lines = countLines(config.fields)
        return (
            <div className="registration-form-address-container">
                <div className="registration-form-row">
                    <div className="registration-step-field--half">
                        <TextInput
                            formId={formId}
                            handleChange={handleChangeAddressField}
                            inputId={'addressType'}
                            label={config.fields.addressType.label}
                            value={customerInformation.address.addressType}
                            options={addressTypes}
                            error={errors.address.addressType}
                        />
                    </div>
                    <div className="registration-step-field--half">
                        <TextInput
                            formId={formId}
                            handleChange={handleChangeAddressField}
                            inputId={'language'}
                            label={'Preferred Language'}
                            value={customerInformation.address.language}
                            options={languages}
                            error={errors.address.language}
                        />
                    </div>
                </div>
                {lines.map((line, i) => {
                    return renderAddressLine(line, i)
                })}
            </div>
        )
    }

    return (
        <MuiThemeProvider>
            <FormWrapper>
                <HorizontalStepper activeStep={CUSTOMER_INFORMATION_STEP} />
                <Panel>
                    <div className="registration-step-title">Customer Information</div>
                    <form noValidate autoComplete="off">
                        <div className="registration-step-field">
                            <div className="registration-step-field__input">
                                <TextInput
                                    formId={formId}
                                    handleChange={handleChange}
                                    inputId={'email'}
                                    label={'Email'}
                                    value={customerInformation.email}
                                    fullWidth
                                    error={errors.email}
                                />
                            </div>
                        </div>
                        <div className="registration-step-field--small">
                            <TextInput
                                formId={formId}
                                handleChange={handleChange}
                                inputId={'salutation'}
                                label={'Salutation'}
                                value={customerInformation.salutation}
                                options={salutationOptions}
                                error={errors.salutation}
                            />
                        </div>
                        <div className="registration-step-field">
                            <div className="registration-step-field__input">
                                <TextInput
                                    formId={formId}
                                    handleChange={handleChange}
                                    inputId={'firstName'}
                                    label={'First Name'}
                                    value={customerInformation.firstName}
                                    fullWidth
                                    error={errors.firstName}
                                />
                            </div>
                        </div>
                        <div className="registration-step-field">
                            <div className="registration-step-field__input">
                                <TextInput
                                    formId={formId}
                                    handleChange={handleChange}
                                    inputId={'lastName'}
                                    label={'Last Name'}
                                    value={customerInformation.lastName}
                                    fullWidth
                                    error={errors.lastName}
                                />
                            </div>
                        </div>
                        <div className="registration-step-field">
                            <div className="registration-step-field__input">
                                <TextInput
                                    formId={formId}
                                    handleChange={handleChange}
                                    inputId={'middleName'}
                                    label={'Middle Name (optional)'}
                                    value={customerInformation.middleName}
                                />
                            </div>
                        </div>
                        <div className="registration-step-field">
                            <div className="registration-step-field__input">
                                <TextInput
                                    formId={formId}
                                    handleChange={handleChange}
                                    inputId={'nickName'}
                                    label={'Nickname'}
                                    value={customerInformation.nickName}
                                    error={errors.nickName}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="registration-step-field">
                            <TextInput
                                formId={formId}
                                handleChange={handleChangeCountry}
                                inputId={'country'}
                                label={'Country'}
                                value={customerInformation.country}
                                options={countryOptions}
                                error={errors.country}
                            />
                        </div>
                        {customerInformation.country !== 0 && renderAddress()}
                        <div className="registration-step-field">
                            <TextInput
                                formId={formId}
                                handleChange={handleChange}
                                inputId={'countryCode'}
                                label={'Country Code'}
                                value={customerInformation.countryCode}
                                options={countryCodeOptions}
                                error={errors.countryCode}
                            />
                        </div>
                        <div className="registration-step-field">
                            <div className="registration-step-field__input">
                                <TextInput
                                    formId={formId}
                                    handleChange={handleChange}
                                    inputId={'phoneNumber'}
                                    label={'Phone Number'}
                                    value={customerInformation.phoneNumber}
                                    error={errors.phoneNumber}
                                />
                            </div>
                        </div>
                    </form>
                    <RegistrationNavigationButtons
                        activeStep={CUSTOMER_INFORMATION_STEP}
                        onNextClick={onNextClick}
                    />
                </Panel>
            </FormWrapper>
        </MuiThemeProvider>
    )
}
