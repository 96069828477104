import React from 'react'
import './theme.scss'
import back from '../../assets/images/icon-back.svg'

export function SecondaryButton({ clickAction, label, isBack }) {
    return (
        <div className="button button--secondary" onClick={clickAction}>
            {isBack && <img className="button-back-icon" src={back} width={16} alt="back" />}
            {label}
        </div>
    )
}
